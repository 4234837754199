import React from "react";
import Billy from "../images/Billy.png";
import Giancarlo from "../images/Giancarlo.png";
import Kris from "../images/Kris.png";
import Chris from "../images/Chris.png";
import Logo from "../images/line-art.png";
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="welcome-title">Meet the Team</h1>
        <div className="team-section">
          <div className="team-member">
            <img className="member-img" src={Billy} alt="Billy" />
            <p className="member-description">Billy has been reefing for just over three years. He got his start with a catalina goby biotope and now specializes in alveopora, acropora and carpet anemones. In the dry world, he has a pet golden retriever, runs esports competitions as a job and enjoys drinking high-end green tea.</p>
          </div>
          <div className="team-member-2">
            <img className="member-img" src={Giancarlo} alt="Giancarlo" />
            <p className="member-description-2">Giancarlo is an experienced reefer with a decade of experience under his belt, having embarked on this fulfilling journey back in 2011. Throughout these years, he has had the privilege to explore various aspects of the saltwater hobby, learning and growing with each encounter. Apart from his love for reefing, Giancarlo finds joy in photography, gaming, and lapel pin collecting.</p>
          </div>
          <div className="team-member">
            <img className="member-img" src={Kris} alt="Kris" />
            <p className="member-description">An avid reefer, Kris is captivated by the microcosm of life that thrives in Nano tanks. His passion for the aquatic universe has been on a meteoric rise, turning him into not just a casual hobbyist but a coral addict. Beyond the reef, Kris is a modern-day Renaissance individual with an expansive palette of interests.</p>
          </div>
          <div className="team-member-2">
            <img className="member-img" src={Chris} alt="Chris" />
            <p className="member-description-2">Chris has been reefing for just under thirty years. He got his start in freshwater, and went on to get a degree in marine biology. After working in ecology made him homeless, he went into working on computers, and went back to keeping aquariums for fun.</p>
          </div>
        </div>

        <div className="logo-img">
          <img src={Logo} alt="line-logo" />
        </div>

        <section className="section">
          <h2>Mission Statement</h2>
          <p>
            Our mission is to educate and create awareness on coral reefs and saltwater keeping. We aspire to guide individuals on the path to successful reefing while fostering a strong sense of responsibility towards ocean life conservation. Founded in 2023, Slice of the Reef is an organization committed to providing educational resources, podcasts, and our expertise in the saltwater keeping hobby to our fellow reefing community.
          </p>
        </section>

        <section className="section">
          <h2>Why It Matters</h2>
          <p>
            Coral reefs are among the most biodiverse ecosystems on the planet, offering invaluable services, from supporting fisheries to protecting coastlines from erosion. The decline of these ecosystems not only affects marine life but has a profound impact on us as well. Whether it's through volunteering, participating in our educational programs, or making a donation, your support makes a difference in our collective mission to protect and preserve our oceans.
          </p>
        </section>

        <section className="section">
          <form action="https://formspree.io/f/xbjvwvyy" method="POST">
            <h2>Contact Us</h2>
            <label>
              Your email:
              <br /><br />
              <input type="email" name="email" />
            </label>
            <label>
              Your message:
              <br /><br />
              <textarea name="message"></textarea>
            </label>
            <button type="submit">Send</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default About;
