import React, { useState } from 'react';
import './Calculators.css';
import Tank from '../components/Calculators/Volume';
import Sump from '../components/Calculators/Sump';
import Sandbed from '../components/Calculators/Sandbed';
import Salinity from '../components/Calculators/Salinity';
import Supplements from '../components/Calculators/Supplements';
import Dosing from '../components/Calculators/Dosing';
import Quarantine from '../components/Calculators/Quarantine';
import Equipment from '../components/Calculators/Equipment';

const CalculatorPage = () => {
  const [showVolumeDropdown, setShowVolumeDropdown] = useState(false);
  const [showDosingDropdown, setShowDosingDropdown] = useState(false);
  const [showSalinityDropdown, setShowSalinityDropdown] = useState(false);
  const [showQuarantineDropdown, setShowQuarantineDropdown] = useState(false);
  const [showEquipmentDropdown, setShowEquipmentDropdown] = useState(false);
  const [activeCalculator, setActiveCalculator] = useState('tank');
  const [selectedDosingType, setSelectedDosingType] = useState('');
  const [selectedSalinityType, setSelectedSalinityType] = useState('');
  const [selectedQuarantineType, setSelectedQuarantineType] = useState('');
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('');

  const toggleVolumeDropdown = () => {
    setShowVolumeDropdown(!showVolumeDropdown);
    setShowDosingDropdown(false);
    setShowSalinityDropdown(false);
    setShowQuarantineDropdown(false);
    setShowEquipmentDropdown(false);
  };

  const toggleDosingDropdown = () => {
    setShowDosingDropdown(!showDosingDropdown);
    setShowVolumeDropdown(false);
    setShowSalinityDropdown(false);
    setShowQuarantineDropdown(false);
    setShowEquipmentDropdown(false);
  };

  const toggleSalinityDropdown = () => {
    setShowSalinityDropdown(!showSalinityDropdown);
    setShowVolumeDropdown(false);
    setShowDosingDropdown(false);
    setShowQuarantineDropdown(false);
    setShowEquipmentDropdown(false);
  };

  const toggleQuarantineDropdown = () => {
    setShowQuarantineDropdown(!showQuarantineDropdown);
    setShowVolumeDropdown(false);
    setShowDosingDropdown(false);
    setShowSalinityDropdown(false);
    setShowEquipmentDropdown(false);
  };

  const toggleEquipmentDropdown = () => {
    setShowEquipmentDropdown(!showEquipmentDropdown);
    setShowVolumeDropdown(false);
    setShowDosingDropdown(false);
    setShowSalinityDropdown(false);
    setShowQuarantineDropdown(false);
  };

  const selectCalculator = (calculator) => {
    setActiveCalculator(calculator);
    setSelectedDosingType('');
    setSelectedSalinityType('');
    setSelectedQuarantineType('');
    setSelectedEquipmentType('');
    if (['tank', 'sump', 'sandbed'].includes(calculator)) {
      setShowVolumeDropdown(true);
    }
  };

  const selectDosing = (dosingType) => {
    setActiveCalculator('dosing');
    setSelectedDosingType(dosingType);
  };

  const selectSalinity = (salinityType) => {
    setActiveCalculator('salinity');
    setSelectedSalinityType(salinityType);
  };

  const selectQuarantine = (quarantineType) => {
    setActiveCalculator('quarantine');
    setSelectedQuarantineType(quarantineType);
  };

  const selectEquipment = (equipmentType) => {
    setActiveCalculator('equipment');
    setSelectedEquipmentType(equipmentType);
  };

  const selectSupplements = () => {
    setActiveCalculator('supplements');
    setShowVolumeDropdown(false);
    setShowDosingDropdown(false);
    setShowSalinityDropdown(false);
    setShowQuarantineDropdown(false);
    setShowEquipmentDropdown(false);
  };

  // Calculator components mapping
  const calculatorComponents = {
    tank: Tank,
    sump: Sump,
    sandbed: Sandbed,
    salinity: () => <Salinity salinityType={selectedSalinityType} />,
    supplements: Supplements,
    dosing: () => <Dosing dosingType={selectedDosingType} />,
    quarantine: () => <Quarantine quarantineType={selectedQuarantineType} />,
    equipment: () => <Equipment equipmentType={selectedEquipmentType} />,
  };

  // Dynamic calculator component rendering
  const ActiveCalculatorComponent = activeCalculator ? calculatorComponents[activeCalculator] : null;

  return (
    <div className="calculator-page-container">
      <h1 className='calculator-header'>Reefing Calculators</h1>
      <div className='calculators'>
        <div className="calculator-nav">
          <div className="volume-dropdown-container">
            <button className={`calc-button ${showVolumeDropdown ? 'active' : ''}`} 
              onClick={() => {
                toggleVolumeDropdown();
                setActiveCalculator('tank');
              }}>
              Volume
            </button>
            {showVolumeDropdown && (
              <div className="volume-dropdown">
                <button className={`dropdown-item ${activeCalculator === 'tank' ? 'active' : ''}`} onClick={() => selectCalculator('tank')}>Tank</button>
                <button className={`dropdown-item ${activeCalculator === 'sump' ? 'active' : ''}`} onClick={() => selectCalculator('sump')}>Sump</button>
                <button className={`dropdown-item ${activeCalculator === 'sandbed' ? 'active' : ''}`} onClick={() => selectCalculator('sandbed')}>Sandbed</button>
              </div>
            )}
          </div>
          <div className="volume-dropdown-container">
            <button className={`calc-button ${showSalinityDropdown ? 'active' : ''}`} onClick={() => {
              toggleSalinityDropdown();
            }}>
              Salinity
            </button>
            {showSalinityDropdown && (
              <div className="volume-dropdown">
                <button className={`dropdown-item ${selectedSalinityType === 'saltMix' ? 'active' : ''}`} onClick={() => selectSalinity('saltMix')}>Salt Mix</button>
                <button className={`dropdown-item ${selectedSalinityType === 'final' ? 'active' : ''}`} onClick={() => selectSalinity('final')}>Final</button>
                <button className={`dropdown-item ${selectedSalinityType === 'target' ? 'active' : ''}`} onClick={() => selectSalinity('target')}>Target</button>
                <button className={`dropdown-item ${selectedSalinityType === 'RODI' ? 'active' : ''}`} onClick={() => selectSalinity('RODI')}>RODI</button>
              </div>
            )}
          </div>
          <div className="volume-dropdown-container">
            <button className={`calc-button ${showDosingDropdown ? 'active' : ''}`} onClick={() => {
              toggleDosingDropdown();
            }}>
              Dosing
            </button>
            {showDosingDropdown && (
              <div className="volume-dropdown">
                <button className={`dropdown-item ${selectedDosingType === 'alkalinity' ? 'active' : ''}`} onClick={() => selectDosing('alkalinity')}>Alkalinity</button>
                <button className={`dropdown-item ${selectedDosingType === 'calcium' ? 'active' : ''}`} onClick={() => selectDosing('calcium')}>Calcium</button>
                <button className={`dropdown-item ${selectedDosingType === 'magnesium' ? 'active' : ''}`} onClick={() => selectDosing('magnesium')}>Magnesium</button>
                <button className={`dropdown-item ${selectedDosingType === 'nitrate' ? 'active' : ''}`} onClick={() => selectDosing('nitrate')}>Nitrate</button>
                <button className={`dropdown-item ${selectedDosingType === 'phosphate' ? 'active' : ''}`} onClick={() => selectDosing('phosphate')}>Phosphate</button>
              </div>
            )}
          </div>
          <button
            className={`calc-button ${activeCalculator === 'supplements' ? 'active' : ''}`}
            onClick={() => {
              selectSupplements();
            }}>
            Supplements
          </button>
          <div className="volume-dropdown-container">
            <button className={`calc-button ${showQuarantineDropdown ? 'active' : ''}`} onClick={() => {
              toggleQuarantineDropdown();
            }}>
              Quarantine
            </button>
            {showQuarantineDropdown && (
              <div className="volume-dropdown">
                <button className={`dropdown-item ${selectedQuarantineType === 'tankTransfer' ? 'active' : ''}`} onClick={() => selectQuarantine('tankTransfer')}>TTM Schedule</button>
                <button className={`dropdown-item ${selectedQuarantineType === 'copper' ? 'active' : ''}`} onClick={() => selectQuarantine('copper')}>Copper Power</button>
                <button className={`dropdown-item ${selectedQuarantineType === 'medication' ? 'active' : ''}`} onClick={() => selectQuarantine('medication')}>Medication</button>
              </div>
            )}
          </div>
          <div className="volume-dropdown-container">
            <button className={`calc-button ${showEquipmentDropdown ? 'active' : ''}`} onClick={() => {
              toggleEquipmentDropdown();
            }}>
              Equipment
            </button>
            {showEquipmentDropdown && (
              <div className="volume-dropdown">
                <button className={`dropdown-item ${selectedEquipmentType === 'returnPump' ? 'active' : ''}`} onClick={() => selectEquipment('returnPump')}>Return Pump</button>
                <button className={`dropdown-item ${selectedEquipmentType === 'hydrometerCalibration' ? 'active' : ''}`} onClick={() => selectEquipment('hydrometerCalibration')}>Hydrometer Calibration</button>
                <button className={`dropdown-item ${selectedEquipmentType === 'filterMedia' ? 'active' : ''}`} onClick={() => selectEquipment('filterMedia')}>Filter Media</button>
                <button className={`dropdown-item ${selectedEquipmentType === 'conversions' ? 'active' : ''}`} onClick={() => selectEquipment('conversions')}>Conversions</button>
              </div>
            )}
          </div>
        </div>
        <div className="calculator-display">
          {ActiveCalculatorComponent && <ActiveCalculatorComponent />}
        </div>
      </div>
    </div>
  );
};

export default CalculatorPage;
