import React, { useState } from 'react';
import productsData from './data/dosing.json'; 
import './styles.css';

const Dosing = ({ dosingType }) => {
  const products = productsData[dosingType];
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [currentValue, setCurrentValue] = useState('');
  const [desiredValue, setDesiredValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [result, setResult] = useState(null);
  const [alkWarn, setAlkWarn] = useState(null);

  const litersToGallons = (liters) => liters * 0.264172;

  const calculateDose = () => {
    const selected = products.find(product => product.Name === selectedProduct);
    if (!selected) return;
    
    const test = (desiredValue - currentValue)
    if (test > 1 && dosingType === 'alkalinity') {
      setAlkWarn("*It's advisable to avoid increasing alkalinity by more than 1 point per dosing session. Rapid spikes in alkalinity can be detrimental.")
    } else {
      setAlkWarn(null)
    }

    const volumeInLiters = volumeUnit === 'liters' ? litersToGallons(parseFloat(volume)) : parseFloat(volume);
    const difference = parseFloat(desiredValue) - parseFloat(currentValue);
    const amountNeeded = volumeInLiters * difference * selected.Factor;

    setResult(`${amountNeeded.toFixed(2)} ${selected.Unit}`);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };  

  return (
    <div>
      <h2>{capitalizeFirstLetter(dosingType)} Calculator</h2>
      <input className='input' type="number" placeholder="Volume" value={volume} onChange={e => setVolume(e.target.value)} />
      <label>
          <input
            type="radio"
            value="gallons"
            checked={volumeUnit === 'gallons'}
            onChange={() => setVolumeUnit('gallons')}
          />
          Gallons
        </label>
        <label>
          <input
            type="radio"
            value="liters"
            checked={volumeUnit === 'liters'}
            onChange={() => setVolumeUnit('liters')}
          />
          Liters
        </label>
      <input className='input' type="number" placeholder="Current Value" value={currentValue} onChange={e => setCurrentValue(e.target.value)} />
      <input className='input' type="number" placeholder="Desired Value" value={desiredValue} onChange={e => setDesiredValue(e.target.value)} />
      <select className='dropdown' onChange={e => setSelectedProduct(e.target.value)} value={selectedProduct}>
        <option value="">Select a Product</option>
        {products.map((product, index) => (
          <option key={index} value={product.Name}>{product.Name}</option>
        ))}
      </select>
      <button className='button' onClick={calculateDose}>Calculate</button>
      {result && <p className='calculation'>Amount Needed: {result}</p>}
      <p style={{fontSize: 'smaller', marginTop: '0.5em', color: 'red'}}>{alkWarn}</p>
    </div>
  );
};

export default Dosing;
