import React, { useState } from 'react';
import supplementsData from './data/supplements.json';
import codesData from './data/codes.json'; // Ensure codesData is correctly imported
import './styles.css';

const Supplements = () => {
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [selectedSupplement, setSelectedSupplement] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [codeMessages, setCodeMessages] = useState([]);
  const [foodType, setFoodType] = useState('');

  const litersToGallons = (liters) => liters * 0.264172;

  const calculateSupplement = () => {

    const supplementDetails = supplementsData.find(supplement => supplement.Name === selectedSupplement);
    if (!supplementDetails) return;

    const volumeInGallons = volumeUnit === 'liters' ? litersToGallons(parseFloat(volume)) : parseFloat(volume);
    const recommendationAmount = volumeInGallons * supplementDetails['Amount per US Gallon'];

    const messages = supplementDetails.Code.map((code, index) => {
      const codeDetail = codesData.find(c => c.Code === code);
      const stars = '*'.repeat(index + 1); // Generate stars based on the index
      return codeDetail ? `${stars} ${codeDetail.Message}` : `${stars} No message found for code`;
    });

    setRecommendation(`${recommendationAmount.toFixed(2)} ${supplementDetails.Units}`);
    setCodeMessages(messages);
    setFoodType(supplementDetails.Type)
  };

  // Get a list of unique manufacturers
  const manufacturers = Array.from(new Set(supplementsData.map(supplement => supplement.Manufacturer)));

  // Filter supplements by selected manufacturer
  const filteredSupplements = supplementsData.filter(supplement => supplement.Manufacturer === selectedManufacturer);

  return (
    <div className="supplements-calculator">
      <h2>Supplements Calculator</h2>
      <div>
        <input
          className='input' 
          type="number"
          placeholder="Volume"
          value={volume}
          onChange={e => setVolume(e.target.value)}
        />
        <label>
          <input
            type="radio"
            value="gallons"
            checked={volumeUnit === 'gallons'}
            onChange={() => setVolumeUnit('gallons')}
          />
          Gallons
        </label>
        <label>
          <input
            type="radio"
            value="liters"
            checked={volumeUnit === 'liters'}
            onChange={() => setVolumeUnit('liters')}
          />
          Liters
        </label>
      </div>
      <div>
        <label>Choose Manufacturer: </label>
        <select
          className='dropdown'
          value={selectedManufacturer}
          onChange={(e) => {
            setSelectedManufacturer(e.target.value);
            // Reset selectedSupplement when manufacturer changes
            setSelectedSupplement('');
          }}
        >
          <option value="">Select a Manufacturer</option>
          {manufacturers.map((manufacturer, index) => (
            <option key={index} value={manufacturer}>{manufacturer}</option>
          ))}
        </select>
      </div>
      {selectedManufacturer && (
        <div>
          <label>Choose Supplement: </label>
          <select
            className='dropdown'
            value={selectedSupplement}
            onChange={e => setSelectedSupplement(e.target.value)}
            disabled={!selectedManufacturer}
          >
            <option value="">Select a Supplement</option>
            {filteredSupplements.map((supplement, index) => (
              <option key={index} value={supplement.Name}>{supplement.Name}</option>
            ))}
          </select>
        </div>
      )}
      <button className='button' onClick={calculateSupplement}>Calculate</button>
      {recommendation && (
        <div>
          <p className='calculation'>Recommended Amount: {recommendation}</p>
          <h5>Food Type: {foodType}</h5>
          {codeMessages.map((message, index) => (
            <p key={index} style={{fontSize: 'smaller', marginTop: '0.5em'}}>
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Supplements;
